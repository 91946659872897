import './App.css';

import {
  useEffect,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';

function App() {
  const {userId} = useParams();

  const [user, setUser] = useState(null);

  const fetchInfo = async () => {
    try {
      const mongoIdRegex = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
      const isIdValid = mongoIdRegex.test(userId);

      if (!isIdValid) {
        setUser(null);
        return;
      }
      //https://api.exafm.com/qr/66da1825e26720f79b773ac1?mode=unset&authToken=ZCnxo811wAr2dlmpYvGysj6CsDvOrlqi
      let response = await fetch(`https://api.exafm.com/user/${userId}?authToken=ZCnxo811wAr2dlmpYvGysj6CsDvOrlqi`);
      let data = await response.json();
      setUser(data.data.user);
    } catch (e) {
      setUser(null);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchInfo();
    })();
  }, [userId]); // Añadí userId como dependencia para refetch si el userId cambia

  const UserInfo = () => (
    <> 
      <img src="img/back2.jpg" alt="Amores de Garra Fest" class="desktop-img" />
      <img src="img/back.jpg" alt="Amores de Garra Fest" class="mobile-img" />
    </>
  );

  const NotFound = () => (
    <p>Usuario no valido</p>
  );

  return (
    <div>
      <img src="img/back2.jpg" alt="Amores de Garra Fest" class="desktop-img" />
      <img src="img/back.jpg" alt="Amores de Garra Fest" class="mobile-img" />
    </div>
  );
}

export default App;
